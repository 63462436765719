class O extends Error {
  constructor(r, o = "", E) {
    const i = o + (E ? ` |> ${E.message}` : "");
    super(i), this.code = r, this.message = o, this.error = E, this.message = i, this.name = this.constructor.name, typeof Error.captureStackTrace == "function" ? Error.captureStackTrace(this, this.constructor) : (this.cause = E, this.stack = new Error(o).stack);
  }
}
var R = /* @__PURE__ */ ((t) => (t.GOOGLE = "google", t.FACEBOOK = "facebook", t.TWITTER = "twitter", t.APPLE = "apple", t.REGISTER = "register", t.LOGIN = "login", t))(R || {}), c = /* @__PURE__ */ ((t) => (t.Section = "section", t.Actions = "actions", t.Divider = "divider", t.Input = "input", t.Header = "header", t.Context = "context", t))(c || {}), s = /* @__PURE__ */ ((t) => (t.Button = "button", t.Checkboxes = "checkboxes", t.EmailTextInput = "email_text_input", t.NumberInput = "number_input", t.PlainTextInput = "plain_text_input", t.RadioButtons = "radio_buttons", t.StaticSelect = "static_select", t.ExternalSelect = "external_select", t.MultiStaticSelect = "multi_static_select", t.MultiExternalSelect = "multi_external_select", t.ImageElement = "image", t))(s || {}), _ = /* @__PURE__ */ ((t) => (t.PlainText = "plain_text", t.Markdown = "mrkdwn", t))(_ || {});
const f = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  BlockType: c,
  ElementType: s,
  TextObjectTextType: _
}, Symbol.toStringTag, { value: "Module" }));
var e = /* @__PURE__ */ ((t) => (t.FACEBOOK = "facebook", t.INSTAGRAM = "instagram", t.LINKEDIN = "linkedin", t.REDDIT = "reddit", t.RESTREAM = "restream", t.YOUTUBE = "youtube", t.TIKTOK = "tiktok", t.TWITTER = "twitter", t.TWITCH = "twitch", t.WHATSAPP = "whatsapp", t))(e || {});
const n = {
  twitter: {
    name: "X",
    color: "#000",
    secondaryColor: "#FFF"
  },
  facebook: {
    name: "Facebook",
    color: "#1877F2",
    secondaryColor: "#FFF"
  },
  instagram: {
    name: "Instagram",
    color: "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%)",
    secondaryColor: "#FFF"
  },
  linkedin: {
    name: "LinkedIn",
    color: "#0A66C2",
    secondaryColor: "#FFF"
  },
  youtube: {
    name: "YouTube",
    color: "#FF0000",
    secondaryColor: "#FFF"
  },
  tiktok: {
    name: "TikTok",
    color: "#FFFFFF",
    secondaryColor: "#000"
  },
  reddit: {
    name: "Reddit",
    color: "#FF5700",
    secondaryColor: "#FFF"
  },
  restream: {
    name: "Restream",
    color: "#000000",
    secondaryColor: "#FFF"
  },
  twitch: {
    name: "Twitch",
    color: "#6441A4",
    secondaryColor: "#FFF"
  },
  whatsapp: {
    name: "WhatsApp",
    color: "linear-gradient(36deg, rgba(45,182,64,1) 0%, rgba(88,207,99,1) 100%)",
    secondaryColor: "#FFF"
  }
}, P = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var I = /* @__PURE__ */ ((t) => (t.ParticipantOnly = "participant-only", t.TeamVisible = "team-visible", t))(I || {});
const K = 1001, Y = 1002, k = 1010, V = 1011, W = 1012, x = 2e3, y = 2034, H = 2076, j = 2088, B = 2084, $ = 2086, z = 2133, Z = 2134, X = 2139, Q = 2107;
var l = /* @__PURE__ */ ((t) => (t.NotStarted = "Not Started", t.InProgress = "In Progress", t.Completed = "Completed", t.WaitingOnSomeoneElse = "Waiting on someone else", t.Deferred = "Deferred", t))(l || {}), A = /* @__PURE__ */ ((t) => (t.Call = "Call", t.Meeting = "Meeting", t.Other = "Other", t.Email = "Email", t))(A || {}), N = /* @__PURE__ */ ((t) => (t.Lead = "lead", t.Account = "account", t.Opportunity = "opportunity", t))(N || {}), C = /* @__PURE__ */ ((t) => (t.Contact = "contact", t.Company = "company", t.Deal = "deal", t))(C || {}), d = /* @__PURE__ */ ((t) => (t.DealToCompany = "deal_to_company", t))(d || {});
const q = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null
}, Symbol.toStringTag, { value: "Module" }));
var D = /* @__PURE__ */ ((t) => (t.one = "one", t.group = "group", t))(D || {}), m = /* @__PURE__ */ ((t) => (t[t.portrait = 0] = "portrait", t[t.landscape = 1] = "landscape", t))(m || {}), T = /* @__PURE__ */ ((t) => (t.HLS = ".m3u8", t.MPD = ".mpd", t))(T || {}), u = /* @__PURE__ */ ((t) => (t.MeetingMemory = "meeting-memory", t.MeetingMemorySpeech = "meeting-memory-speech", t.CrmIntegrations = "crm-integrations", t.AiFeedVisibility = "ai-feed-visibility", t.Dev = "dev", t.RecallCalendarIntegration = "recall-calendar-integration", t))(u || {}), L = /* @__PURE__ */ ((t) => (t.DISABLED = "DISABLED", t.PENDING = "PENDING", t.READY = "READY", t))(L || {}), v = /* @__PURE__ */ ((t) => (t[t.NONE = 0] = "NONE", t[t.DAILY = 1] = "DAILY", t[t.WEEKLY = 2] = "WEEKLY", t[t.MONTHLY = 3] = "MONTHLY", t[t.YEARLY = 4] = "YEARLY", t))(v || {});
class a {
  constructor(r, o, E) {
    this.success = r, this.error = o, this.value = E, Object.freeze(this);
  }
  getValue() {
    if (!this.success)
      throw new Error("Cant retrieve the value from a failed result.");
    return this.value;
  }
  getError() {
    if (this.success)
      throw new Error("Cant retrieve the error from a successful result.");
    return this.error;
  }
  static ok(r) {
    return new a(!0, void 0, r);
  }
  static fail(r, o = "") {
    return new a(!1, new O(r, o));
  }
  static error(r) {
    return new a(!1, r);
  }
}
var h = /* @__PURE__ */ ((t) => (t.ADMIN = "admin", t.GUEST = "guest", t.GUEST_USER = "guest_user", t.HOST = "host", t.PRODUCER = "producer", t.SESSION_OWNER = "session_owner", t.VIEWER = "viewer", t))(h || {});
const J = "Authentication", tt = "Braid", et = "Meeting Memory", rt = "Chat", ot = "Stream", nt = "Effects SDK";
var G = /* @__PURE__ */ ((t) => (t.PartyMode = "party-mode", t.ListeningMode = "listening-mode", t))(G || {});
const Et = ["everyone", "owner", "owner_same_domain"];
var F = /* @__PURE__ */ ((t) => (t.CANCELLED = "cancelled", t.SCHEDULED = "scheduled", t.IN_SESSION = "active", t.ENDED = "ended", t.ARCHIVED = "archived", t))(F || {}), w = /* @__PURE__ */ ((t) => (t.IN_REVIEW = "inReview", t.GRANTED = "granted", t.IGNORED = "ignored", t.DENIED = "denied", t))(w || {}), M = /* @__PURE__ */ ((t) => (t.Google = "google", t))(M || {});
const b = {
  [e.TWITTER]: n.twitter,
  [e.LINKEDIN]: n.linkedin,
  [e.INSTAGRAM]: n.instagram,
  [e.FACEBOOK]: n.facebook,
  [e.YOUTUBE]: n.youtube,
  [e.TIKTOK]: n.tiktok,
  [e.REDDIT]: n.reddit,
  email: { name: "email", color: "#DDDDDD", secondaryColor: "#1E2431" },
  website: { name: "website", color: "#DDDDDD", secondaryColor: "#1E2431" }
}, at = Object.keys(b), it = {
  [e.FACEBOOK]: ({ url: t = "" } = {}) => `//www.facebook.com/sharer.php?u=${encodeURIComponent(t)}`,
  [e.LINKEDIN]: ({ url: t = "" } = {}) => `//www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(t)}`,
  [e.WHATSAPP]: ({ text: t = "" } = {}) => `//wa.me/?text=${encodeURIComponent(t)}`,
  [e.TWITTER]: ({ text: t = "" } = {}) => `//twitter.com/intent/tweet?text=${encodeURIComponent(t)}&hashtags=waitroom`
};
var g = /* @__PURE__ */ ((t) => (t[t.NONE = 0] = "NONE", t[t.DISABLED = 1] = "DISABLED", t[t.ENABLED = 2] = "ENABLED", t))(g || {});
const ct = "AUTH_TOKENS", st = "BROWSER_SUPPORT", _t = "DEVICE_SETTINGS", St = "DEVICES", Ot = "g", Rt = "LAST_USED_INTEGRATION", It = "NOTIFICATIONS", lt = "PLAYER", At = "STREAM_SETTINGS", Nt = "TEST_CAM_MIC", Ct = "USER_SETTINGS";
var U = /* @__PURE__ */ ((t) => (t.Owner = "owner", t.Member = "member", t))(U || {}), p = /* @__PURE__ */ ((t) => (t[t.MALE = 0] = "MALE", t[t.FEMALE = 1] = "FEMALE", t[t.OTHER = 2] = "OTHER", t))(p || {});
const S = {
  intro: "0",
  addPeopleToSession: "1",
  connectCalendar: "2",
  watchDemo: "3",
  tryMeetingMemory: "4"
}, dt = Object.values(S), Dt = Object.values(S).filter(
  (t) => t !== "0"
);
export {
  O as AppError,
  R as AuthType,
  f as BlockKit,
  e as Brand,
  n as Brands,
  P as CMS,
  I as DataVisibility,
  k as ERROR_AVATAR_UPLOAD_ERROR,
  Y as ERROR_GENERAL,
  B as ERROR_SESSION_ACCESS_ALREADY_APPROVED,
  j as ERROR_SESSION_ACCESS_ALREADY_REQUESTED,
  V as ERROR_SESSION_ACCESS_DENIED,
  $ as ERROR_SESSION_ACCESS_DOESNT_EXIST,
  X as ERROR_SESSION_ACCESS_INACTIVE,
  H as ERROR_SESSION_ACCESS_UNAUTHORIZED,
  Z as ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED,
  z as ERROR_SESSION_ACCESS_UNAUTHORIZED_UNLOCKED,
  W as ERROR_SESSION_IS_FULL,
  y as ERROR_SESSION_NOT_FOUND,
  x as ERROR_SESSION_UNAUTHENTICATED,
  Q as ERROR_TRIAL_ALREADY_ACTIVE,
  K as ERROR_UNKNOWN,
  p as Gender,
  d as HubspotAssociationType,
  C as HubspotRecordType,
  et as LOGGER_MEETING_MEMORY,
  J as LOGGER_SERVICE_AUTH,
  tt as LOGGER_SERVICE_BRAID,
  rt as LOGGER_SERVICE_CHAT,
  nt as LOGGER_SERVICE_EFFECTS_SDK,
  ot as LOGGER_SERVICE_STREAM,
  q as MeetingMemory,
  G as MeetingType,
  D as MessageBroadcastType,
  m as Orientation,
  u as PostHogFeatureFlag,
  L as RecordingStatus,
  v as Recurring,
  a as Result,
  h as Role,
  ct as STORAGE_AUTH_TOKENS,
  st as STORAGE_BROWSER_SUPPORT,
  St as STORAGE_DEVICES,
  _t as STORAGE_DEVICE_SETTINGS,
  Ot as STORAGE_GENERAL,
  Rt as STORAGE_LAST_USED_INTEGRATION,
  It as STORAGE_NOTIFICATIONS,
  lt as STORAGE_PLAYER,
  At as STORAGE_STREAM_SETTINGS,
  Nt as STORAGE_TEST_CAM_MIC,
  Ct as STORAGE_USER_SETTINGS,
  N as SalesforceRecordType,
  l as SalesforceTaskStatus,
  A as SalesforceTaskType,
  w as SessionAccessRuleStatus,
  M as SessionCalendarType,
  F as SessionStatus,
  it as Share,
  at as SocialKeys,
  b as Socials,
  g as Status,
  U as TeamMemberRole,
  T as VideoFileExtension,
  Et as summaryEmailRecipients,
  S as userOnboarding,
  Dt as userOnboardingChecklistValues,
  dt as userOnboardingValues
};
