import { createAction as S } from "@waitroom/react-utils";
import { useSelector as U, useDispatch as O } from "react-redux";
import it from "deepmerge";
import { produce as ot } from "immer";
import { authApiService as l, authBaseEndpoint as z, httpService as at, braidService as ut, userApiService as b } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as N, LOGGER_SERVICE_AUTH as m, LOGGER_SERVICE_BRAID as V, ERROR_SESSION_ACCESS_UNAUTHORIZED_LOCKED as ht } from "@waitroom/models";
import { tryCatchAsync as x, withLock as D, retry as dt, isFunction as ft } from "@waitroom/utils";
import { jwtDecode as Tt } from "jwt-decode";
import { useMemo as St } from "react";
import { useMutation as P } from "@tanstack/react-query";
import { isErrorCode as lt, getDetailsErrorCode as _t } from "@waitroom/braid";
import { bearerValue as y } from "@waitroom/http-client";
import { createListenerMiddleware as mt } from "@reduxjs/toolkit";
import { getRequestData as H } from "@waitroom/react-query";
const M = "refresh-token", p = "auth/INIT_AUTH", B = "auth/SETUP_AUTH", Et = "auth/REFRESH_AUTH", j = "auth/LOGOUT", q = "auth/SET_CURRENT_USER", K = "auth/UPDATE_CURRENT_USER", Jt = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", W = "auth/SET_AUTH_STATE", Z = "auth/UPDATE_AUTH_STATE", J = "auth/SET_SUBSCRIPTION_PLAN", Q = "auth/REFRESH_SUBSCRIPTION_PLAN", X = "auth/AUTHENTICATED", kt = "auth/UN_AUTHENTICATED", Y = "auth/GET_CURRENT_USER", Qt = S(p), $ = S(B), R = S(
  W
), T = S(Z), Ut = S(Y), Xt = S(
  q
), Yt = S(K), At = S(j), I = S(X), v = S(kt), tt = S(J), $t = S(Q);
let i;
const te = (e) => {
  i = e.logger;
}, yt = (e) => e.reduce(
  (t, s) => ({
    ...t,
    [s]: !0
  }),
  {}
), w = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, d = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => w,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = Tt(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const s = e || this.getStorageTokens();
    if (!(s != null && s.accessToken)) return w;
    const n = this.decode(s, 600);
    n != null && n.accessToken && this.saveStorageTokens({
      accessToken: n.accessToken,
      refreshToken: n.refreshToken
    });
    const r = n || (t ? await this.refresh(s.refreshToken) : void 0);
    return r != null && r.accessToken ? r : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(N);
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(N, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(N);
  },
  isTokenValid: function(e, t = 0) {
    const s = e ? this.getDecodedToken(e) : void 0;
    return !!s && !this.hasExpired(s.exp, t);
  },
  _refreshFn: async function(e) {
    var c;
    if (!e) return;
    const t = await x(
      () => l.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: s, refreshToken: n } = ((c = t == null ? void 0 : t.data) == null ? void 0 : c.data) || {};
    if (!s) return;
    const r = { accessToken: s, refreshToken: n }, a = this.decode(r);
    if (a)
      return this.saveStorageTokens(r), a;
  },
  refresh: async function(e) {
    var r;
    if (i == null || i.logService(m, 1, "Refreshing auth token"), this._refreshPromise) return await this._refreshPromise;
    const t = (r = this.getStorageTokens()) == null ? void 0 : r.refreshToken, s = e || t;
    if (!s) return;
    this._refreshPromise = this._refreshFn(s);
    const n = await this._refreshPromise || await this._refreshFn(t);
    return i == null || i.logService(m, 1, "Refreshed auth token"), this._refreshPromise = void 0, n;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: s,
      guestSurrogateID: n,
      guestEmail: r,
      guestFullName: a,
      exp: c,
      roleIDs: u = []
    } = this.getDecodedToken(e.accessToken) || {}, o = s || (n ? String(n) : void 0);
    if (!o || this.hasExpired(c, t)) return;
    const f = !s, _ = {
      ...e,
      userId: o,
      isAuthenticated: !0,
      expires: c,
      agentId: o,
      isGuest: f,
      roles: yt(u),
      status: "initialized"
    };
    return f && (_.currentUser = {
      id: String(n),
      firstName: a || "",
      email: r || ""
    }), _;
  },
  logout: async function() {
    return await l.logout({ throwError: !1 }), this.clearStorageTokens(), w;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
}, et = d.getAnonymous(), st = {
  ...et,
  status: void 0
}, ee = (e = st, t) => ot(e, (s) => {
  var n;
  switch (t.type) {
    case W:
      return t.payload || et;
    case Z:
      return s = { ...s, ...t.payload }, s;
    case q:
      return s.currentUser = t.payload, s.userId = (n = t.payload) == null ? void 0 : n.id, s;
    case K:
      return t.payload && (s.currentUser = it(
        s.currentUser || {},
        t.payload
      ), t.payload.id && (s.userId = t.payload.id)), s;
    case J:
      return !s.currentUser || !t.payload || (s.currentUser.subscriptionPlan = t.payload), s;
    default:
      return e;
  }
}), h = (e) => e && e.auth ? e.auth : st, vt = (e) => h(e).isAuthenticated, Pt = (e) => h(e).isGuest, Rt = (e) => h(e).status, se = (e) => h(e).userId, E = (e) => h(e).currentUser, ne = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.id;
}, re = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.customerID;
}, ce = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.email;
}, It = (e) => h(e).roles, nt = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.notificationSettings;
}, ie = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.onboarding;
}, oe = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.subscriptionPlan;
}, ae = (e) => {
  var t;
  return (t = E(e)) == null ? void 0 : t.team;
}, ue = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isPushNotificationEnabledGlobal;
}, he = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isEmailNotificationEnabledGlobal;
}, de = (e) => {
  const { accessToken: t, refreshToken: s, expires: n } = h(e);
  return { accessToken: t, refreshToken: s, expires: n };
}, fe = (e) => h(e).accessToken, Te = (e) => h(e).agentId, Se = () => {
  const e = U(h);
  return {
    state: e,
    isLoading: !e.status || e.status === "loading"
  };
}, le = (e) => {
  const t = U(vt), s = U(Pt), n = U(Rt), r = U(It), [a, c] = St(() => e ? t ? (typeof e == "string" ? [e] : e).reduce(
    (o, f) => {
      const _ = !!r && !!r[f];
      return o[0][f] = _, o[1] = o[1] && _, o;
    },
    [{}, !0]
  ) : [{}, !1] : [{}, !0], [e, t, r]);
  return {
    isAuthenticated: t,
    isGuest: s,
    status: n,
    isLoading: !n || n === "loading",
    hasAccess: c,
    access: a,
    roles: r
  };
}, gt = (e) => ({
  ...e,
  mutationFn: async (t) => {
    const s = {
      ...t,
      email: t.email.toLowerCase()
    };
    return t.clientId && t.redirectUrl ? l.requestOAuthOTP({
      data: {
        ...s,
        redirect_uri: t.redirectUrl,
        client_id: t.clientId,
        state: t.state,
        codeChallenge: t.codeChallenge
      }
    }) : l.requestOTP({ data: s });
  }
}), Ct = (e) => ({
  ...e,
  mutationFn: async ({ email: t, otp: s, clientId: n, state: r, codeChallenge: a }) => n ? l.verifyOAuthOTP({
    data: {
      email: t.toLowerCase(),
      otp: s,
      client_id: n,
      state: r,
      codeChallenge: a
    }
  }) : l.verifyOTP({
    data: {
      email: t.toLowerCase(),
      otp: s
    }
  })
}), Nt = (e) => ({
  ...e,
  mutationFn: async ({
    token: t,
    code: s,
    type: n,
    userDetails: r,
    clientId: a,
    redirectUrl: c,
    state: u,
    codeChallenge: o
  }) => {
    if (!n || !s && !t) throw new Error("Invalid request");
    return a && c ? t ? l.verifyOAuthTokenProvider({
      data: {
        token: t,
        type: n,
        userDetails: r,
        client_id: a,
        redirect_uri: c,
        state: u,
        codeChallenge: o
      }
    }) : l.verifyOAuthCodeProvider({
      data: {
        code: s || "",
        type: n,
        client_id: a,
        redirect_uri: c,
        state: u,
        codeChallenge: o
      }
    }) : t ? l.verifyAuthTokenProvider({
      data: { token: t, type: n, userDetails: r }
    }) : l.verifyAuthCodeProvider({
      data: { code: s || "", type: n }
    });
  }
}), wt = (e) => ({
  ...e,
  mutationFn: async (t) => l.logout(t)
}), _e = (e) => {
  const t = O();
  return P(
    wt({
      ...e,
      onMutate: (...s) => {
        t(T({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (...s) => {
        t(T(d.getAnonymous())), t(v()), e != null && e.onSuccess && e.onSuccess(...s);
      }
    })
  );
}, me = (e) => P(gt(e)), Ee = ({ onRedirect: e, ...t }) => {
  const s = O();
  return P(
    Nt({
      ...t,
      onMutate: (...n) => {
        s(T({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onError: (n, r, a) => {
        s(T({ status: "initialized" })), t != null && t.onError && t.onError(n, r, a);
      },
      onSuccess: (n, r, a) => {
        var u;
        if (s(T({ status: "initialized" })), !(n != null && n.success)) return;
        const c = (u = n.data) == null ? void 0 : u.data;
        if (c) {
          if (r.clientId && r.redirectUrl) {
            "redirect_uri" in c && c.redirect_uri && e(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: o, refreshToken: f } = c;
            s(
              $({
                accessToken: o,
                refreshToken: f
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, r, a);
        }
      }
    })
  );
}, ke = ({ onRedirect: e, ...t }) => {
  const s = O();
  return P(
    Ct({
      ...t,
      onMutate: (...n) => {
        s(T({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (n, r, a) => {
        var u;
        if (s(T({ status: "initialized" })), !(n != null && n.success)) return;
        const c = (u = n.data) == null ? void 0 : u.data;
        if (c) {
          if (r.clientId) {
            "redirect_uri" in c && c.redirect_uri && e(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: o, refreshToken: f } = c || {};
            if (!o) return;
            const _ = r.redirectUrl;
            s(
              $({
                ...r,
                accessToken: o,
                refreshToken: f,
                onCompleted: _ ? () => e(_) : void 0
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, r, a);
        }
      }
    })
  );
}, rt = () => {
  const e = d.getStorageTokens();
  return e != null && e.accessToken ? [d.isTokenValid(e.accessToken, 30), e] : [!1, void 0];
}, Lt = D(M, async (e) => {
  i == null || i.logService(m, 3, "Refresh token interceptor lock");
  const [t, s] = rt();
  if (t || !(s != null && s.refreshToken))
    return s == null ? void 0 : s.accessToken;
  const n = await d.refresh(s.refreshToken);
  if (n != null && n.accessToken)
    return e == null || e(n), n.accessToken;
}), g = async (e, t) => {
  const [s, n] = rt();
  return s && e !== y(n.accessToken) ? n.accessToken : Lt(t);
}, ct = lt([401]), Ue = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (i == null || i.logService(V, 3, "Error interceptor", t.key, s), !t._retry && ct(s)) {
    const r = await g((n = t.headers) == null ? void 0 : n.Authorization, e);
    return r ? (t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(r)
    }, !0) : !1;
  }
  return !1;
}, Ae = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (i == null || i.logService(V, 3, "Retry interceptor", t.key, s), ct(s)) {
    if (t._retry || _t(s) === ht)
      return !1;
    const r = await g((n = t.headers) == null ? void 0 : n.Authorization, e);
    if (!r) return !1;
    t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(r)
    };
  }
  return !0;
}, ye = ({ onRetry: e, onAuthUpdated: t }) => async (s) => {
  var r, a;
  e == null || e(s);
  const n = s == null ? void 0 : s.config;
  if (i == null || i.logLvl(3, "Axios auth interceptor", s), ((r = s == null ? void 0 : s.response) == null ? void 0 : r.status) === 401 && !(n != null && n._retry) && !String(n.url).startsWith(z)) {
    n._retry = !0;
    const c = (a = n.headers.Authorization) == null ? void 0 : a.toString(), u = await g(c, t);
    return i == null || i.logService(m, 3, "Axios auth interceptor", u), u ? (n.headers.Authorization = y(u), at.client(n)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, ve = ({ onRetry: e, onAuthUpdated: t }) => async (s, n) => {
  var c, u;
  e == null || e(n);
  const r = s;
  if ((n && typeof n == "object" ? (n == null ? void 0 : n.code) || (n == null ? void 0 : n.status) : n) === 401 && !(r != null && r.retried) && !String(r.url).startsWith(z)) {
    r.retried = !0;
    const o = (u = (c = r.headers) == null ? void 0 : c.Authorization) == null ? void 0 : u.toString(), f = await g(o, t);
    return f ? (r.headers = {
      ...r.headers,
      Authorization: y(f)
    }, ut.fetch(r)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, G = (e) => !!e.isAuthenticated && !!e.userId && !e.isGuest, k = mt();
let A;
const Ot = (e, t) => new Promise(async (s) => {
  A && clearTimeout(A);
  const { expires: n, refreshToken: r } = e;
  if (!n || !r) return;
  const a = ~~(6e4 + Math.random() * 24e4), c = Math.max(0, n * 1e3 - Date.now() - a);
  i == null || i.logService(m, 3, "Refresh auth token setup timeout", {
    time: c
  }), A = setTimeout(
    D(M, async () => {
      i == null || i.logService(m, 3, "Refresh auth token timeout");
      const o = await d.refresh(e.refreshToken) || await d.logout();
      return o && (t.dispatch(T(o)), t.dispatch(o.isAuthenticated ? I() : v())), s(o);
    }),
    c
  );
}), C = async (e, t) => {
  const s = await Ot(e, t);
  return s != null && s.isAuthenticated && C(s, t), s;
}, bt = 6e5;
let L;
const Dt = (e) => new Promise(async (t) => {
  L = setTimeout(async () => {
    const s = await b.getSubscriptionPlan(e);
    return t(s);
  }, bt);
});
async function F(e) {
  L && clearTimeout(L);
  const t = h(e.getState());
  if (!G(t)) return;
  const s = await Dt(t.userId), n = H(s);
  n && s.success && e.dispatch(tt(n)), F(e);
}
k.startListening({
  type: p,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = await d.setup();
    s.refreshToken && t.fork(async () => {
      C(s, t);
    }), t.dispatch(R(s)), t.dispatch(s.isAuthenticated ? I() : v());
  }
});
k.startListening({
  type: Y,
  effect: async (e, t) => {
    var a;
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!G(s)) return;
    const n = await x(
      () => dt(b.getCurrent, {
        retries: 2
      })
    )();
    if (n != null && n.code && n.code >= 400 && n.code <= 500) {
      t.dispatch(At());
      return;
    }
    const r = (a = H(n)) == null ? void 0 : a.user;
    if (r) {
      const c = {
        id: r.userID,
        firstName: r.userFirstName,
        lastName: r.userLastName,
        ...r
      }, o = { ...h(t.getState()), currentUser: c };
      t.dispatch(R(o)), t.fork(() => F(t));
      return;
    }
  }
});
k.startListening({
  type: B,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: async (e, t) => {
    t.cancelActiveListeners(), i == null || i.logService(m, 1, "SETUP_AUTH");
    const {
      accessToken: s,
      refreshToken: n,
      userId: r
    } = h(t.getState()) || {}, { accessToken: a, refreshToken: c, onCompleted: u } = e.payload || {};
    if (s === a && n === c) return;
    const o = await d.setup({
      accessToken: a,
      refreshToken: c
    });
    o.refreshToken && t.fork(() => C(o, t)), o.isAuthenticated && t.fork(() => F(t)), r === o.userId ? t.dispatch(T(o)) : t.dispatch(R(o)), t.dispatch(I()), t.dispatch(T({ status: "initialized" })), u && (ft(u) ? u() : t.dispatch(u));
  }
});
k.startListening({
  type: X,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(Ut());
  }
});
k.startListening({
  type: j,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(T({ status: "loading" })), A && clearTimeout(A), h(t.getState()).isAuthenticated && await d.logout(), t.dispatch(R(d.getAnonymous())), t.dispatch(v());
  }
});
k.startListening({
  type: Q,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!G(s)) return;
    const n = await b.getSubscriptionPlan(s.userId), r = H(n);
    r && n.success && t.dispatch(tt(r));
  }
});
k.startListening({
  type: Et,
  effect: async (e, t) => {
    t.cancelActiveListeners(), i == null || i.logService(m, 3, "Refreshing auth");
    const s = h(t.getState());
    D(M, async () => {
      const r = await d.refresh(s.refreshToken) || await d.logout();
      r && (t.dispatch(T(r)), t.dispatch(r.isAuthenticated ? I() : v()), r != null && r.isAuthenticated && C(r, t));
    })();
  }
});
export {
  X as AUTHENTICATED,
  Y as GET_CURRENT_USER,
  p as INIT_AUTH,
  j as LOGOUT,
  Et as REFRESH_AUTH,
  Q as REFRESH_SUBSCRIPTION_PLAN,
  B as SETUP_AUTH,
  W as SET_AUTH_STATE,
  q as SET_CURRENT_USER,
  Jt as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  J as SET_SUBSCRIPTION_PLAN,
  kt as UN_AUTHENTICATED,
  Z as UPDATE_AUTH_STATE,
  K as UPDATE_CURRENT_USER,
  st as authInitialState,
  k as authListenerMiddleware,
  ee as authReducer,
  et as authResetState,
  d as authService,
  I as authenticated,
  ye as axiosAuthInterceptor,
  ve as braidOnError,
  Ue as braidOnErrorInterceptor,
  Ae as braidOnRetryInterceptor,
  g as getAccessToken,
  Ut as getCurrentUser,
  Qt as initAuth,
  te as initConfig,
  i as logger,
  At as logout,
  wt as logoutMutation,
  $t as refreshSubscriptionPlan,
  M as refreshTokenLockName,
  gt as requestOTPMutation,
  Te as selectAgentId,
  fe as selectAuthAccessToken,
  h as selectAuthState,
  Rt as selectAuthStatus,
  de as selectAuthToken,
  se as selectAuthUserId,
  E as selectCurrentUser,
  re as selectCurrentUserCustomerId,
  ce as selectCurrentUserEmail,
  he as selectCurrentUserEmailSetting,
  ne as selectCurrentUserId,
  nt as selectCurrentUserNotificationSettings,
  ie as selectCurrentUserOnboarding,
  ue as selectCurrentUserPushSetting,
  It as selectCurrentUserRoles,
  oe as selectCurrentUserSubscriptionPlan,
  ae as selectCurrentUserTeam,
  vt as selectIsAuthenticated,
  Pt as selectIsCurrentUserGuest,
  R as setAuthState,
  Xt as setCurrentUser,
  tt as setSubscriptionPlan,
  $ as setupAuth,
  v as unauthenticated,
  T as updateAuthState,
  Yt as updateCurrentUser,
  Se as useAuth,
  le as useHasAccess,
  _e as useLogout,
  me as useRequestOTP,
  Ee as useVerifyAuthProvider,
  ke as useVerifyOTP,
  Nt as verifyAuthProviderMutation,
  Ct as verifyOTPMutation
};
