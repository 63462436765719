import { format as u } from "date-fns";
import { VideoFileExtension as y, SocialKeys as w } from "@waitroom/models";
const F = (t) => t != null, d = (t) => typeof t == "function", S = d, T = (t) => S(t) ? t() : t, j = (t, ...n) => n.some((e) => typeof t === e), x = (t, n) => n in t, N = (t, n) => t instanceof n, L = (...t) => (n) => t.reduceRight((e, r) => r(e), n), b = (...t) => (n) => t.reduce((e, r) => r(e), n), U = (t) => (...n) => Promise.resolve(t(...n)), v = (t) => (n) => {
  try {
    return t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, Y = (t) => async (n) => {
  try {
    return await t();
  } catch (e) {
    return n ? n(e) : e;
  }
}, P = async (t, n) => {
  var o;
  const e = {
    delay: 300,
    retries: 2,
    ...n
  };
  let r;
  for (let s = 1; s <= e.retries; s++)
    try {
      return await t();
    } catch (i) {
      if (r = i, (o = e.shouldSkip) != null && o.call(e, i)) throw i;
      s < e.retries && await new Promise((c) => setTimeout(c, (e.delay ?? 300) * s));
    }
  throw r;
}, z = (t, n) => {
  let e = Date.now();
  const r = async (o = 1) => {
    var c;
    const s = {
      initialDelay: 300,
      maxDelay: 5e3,
      retries: 3,
      resetTime: 1e4,
      // 10s
      ...n
    }, i = Date.now();
    i - e > s.resetTime && (o = 1), e = i;
    try {
      return await t();
    } catch (f) {
      if (s.onError && await s.onError(f), (c = s.shouldSkip) != null && c.call(s, f) || s.retries !== -1 && o >= s.retries) throw f;
      return s.onRetry && await s.onRetry(f, o), new Promise(
        (g) => setTimeout(
          async () => g(r(o + 1)),
          Math.min(s.initialDelay * 2 ** (o - 1), s.maxDelay)
        )
      );
    }
  };
  return r();
}, C = (t) => (n) => (...e) => t(e) ? n(e) : void 0, _ = () => typeof window < "u", q = (t, n) => {
  const e = {};
  if (!t) return e;
  for (let r = 0; r < t.length; r++) {
    const o = t[r];
    n ? n(e, o) : e[String(o)] = o;
  }
  return e;
}, H = (t, n) => t ? (Array.isArray(n) ? n : [n]).every((r) => t.includes(r)) : !1, I = (t, n) => t ? (Array.isArray(n) ? n : [n]).some((r) => t.includes(r)) : !1, J = (t, n, e = ", ") => {
  if (!t) return "";
  const r = d(n) ? n : (o) => String(o[n] ?? "");
  return t.map(r).filter(Boolean).join(e);
}, V = (t, n, e) => {
  if (!t) return "";
  const r = d(n) ? n : (c) => String(c[n] ?? ""), o = t.map(r).filter(Boolean);
  if (o.length <= 1) return o.join(", ");
  const s = [...o], i = s.pop();
  return `${s.join(", ")} ${e} ${i}`;
};
function Z(t, n) {
  return [...t.filter((e) => n.indexOf(e) === -1), ...n.filter((e) => t.indexOf(e) === -1)];
}
function k(t) {
  const n = Math.floor(Math.random() * t.length);
  return t[n];
}
function W(t, n) {
  const e = {}, r = [];
  for (const o of t) {
    const s = o[n];
    e[s] || (e[s] = !0, r.push(o));
  }
  return r;
}
const a = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"], l = 1024, G = (t, n = 2) => {
  if (t === 0) return "0 B";
  const e = n < 0 ? 0 : n, r = Math.floor(Math.log(t) / Math.log(l));
  return `${parseFloat((t / l ** r).toFixed(e))} ${a[r]}`;
}, Q = (t, n = "B", e = "MB", r = 2) => {
  if (!t || n === e) return t;
  const o = r < 0 ? 0 : r, s = a.indexOf(e), i = a.indexOf(n);
  if (s < 0 || i < 0) return t;
  const c = s - i;
  return parseFloat((t / l ** c).toFixed(o));
}, h = /:00/, K = 864e5, X = 36e5, tt = 6e4, nt = (t) => t ? new Date(t) : /* @__PURE__ */ new Date(), et = (t, n, {
  separator: e = " — ",
  date: r = "EEEE, MMM d, yyyy",
  time: o = "h:mm a"
} = {}) => {
  const s = u(t, `${r}${e}${o}`).replace(h, ""), i = u(n, o).replace(h, "");
  return `${s} - ${i}`;
}, m = (t) => {
  const e = ~~((t < 0 ? 0 : t) / 1e3), r = ~~(e / 60), o = ~~(r / 60);
  return [e % 60, r % 60, o % 24, ~~(o / 24)];
}, p = ["s", "m", "h", "d"], E = (t, n = p) => {
  let e = "";
  for (let r = t.length - 1; r >= 0; r--) {
    const o = t[r], s = n[r];
    o > 0 && s && (e += `${o}${n[r]} `);
  }
  return e.trimEnd();
}, rt = (t, n = p) => {
  const e = m(t);
  return E(e, n);
}, ot = (t) => typeof t == "number" ? t * 1e3 : t, st = (t, n) => {
  const e = new Date(t), r = [`FREQ=${n}`];
  return n === "weekly" ? r.push(`BYDAY=${u(e, "EEEEEE")}`) : n === "monthly" ? r.push(`BYMONTHDAY=${u(e, "d")}`) : n === "yearly" && r.push(`BYMONTH=${u(e, "M")};BYMONTHDAY=${u(e, "d")}`), r.join(";").toUpperCase();
}, it = (t, n = ":") => {
  const [e, r, o, s] = m(t), i = [];
  let c = o;
  return s > 0 && (c += s * 24), c > 0 ? (i.push(String(c)), i.push(String(r).padStart(2, "0"))) : i.push(String(r)), i.push(String(e).padStart(2, "0")), i.join(n);
}, ct = (t, n, e, r) => {
  n.forEach((o) => {
    t.addEventListener(o, e, r);
  });
}, ut = (t, n, e, r) => {
  n.forEach((o) => {
    t.removeEventListener(o, e, r);
  });
}, ft = (t = "") => new DOMParser().parseFromString(t, "text/html").documentElement.textContent || t, at = (t, n, e, r = {}) => t.reduce((o, s) => {
  const i = e[s] ?? n[s];
  return o[s] = s in r || i === void 0 ? i : String(i), o;
}, {});
function lt(t) {
  try {
    return JSON.parse(t), !0;
  } catch {
    return !1;
  }
}
function dt(t, n) {
  if (!t) return n;
  try {
    return JSON.parse(t);
  } catch {
    return n;
  }
}
const $ = typeof navigator < "u" && "locks" in navigator, ht = (t, n, e = {}) => $ ? (...r) => navigator.locks.request(t, e, async () => await n(...r)) : n, mt = (t = 0, n = Number.MAX_SAFE_INTEGER) => Math.floor(Math.random() * (n - t + 1)) + t, pt = (t, n, e = "+ ") => t > n ? `${e}${n}` : `${t}`, gt = (t) => t < 1e3 ? `${t}` : t < 1e6 ? `${Number((t / 1e3).toFixed(1))}k` : `${Number((t / 1e6).toFixed(1))}m`, M = (t) => t != null && (typeof t == "string" || typeof t == "number"), yt = (t) => t !== null && typeof t == "object" && !Array.isArray(t), B = (t) => Object.keys(t);
function wt(t, ...n) {
  return n.length ? n.reduce((r, o) => {
    const { [o]: s, ...i } = r;
    return i;
  }, t) : t;
}
function St(t, n, e) {
  if (!t) return e;
  const o = (typeof n == "string" ? n.split(".") : n).reduce((s, i) => s && s[i] !== void 0 ? s[i] : void 0, t);
  return o !== void 0 ? o : e;
}
function A(t, n, e) {
  return B({ ...t, ...n }).reduce((r, o) => ((e ? e(t[o]) === e(n[o]) : t[o] === n[o]) || (r[o] = n[o]), r), {});
}
const Et = (t, n) => Object.entries(A(t, n)).length === 0, $t = (t, n) => {
  if (!t) return;
  const e = {};
  for (let r = 0; r < t.length; r++) {
    const o = t[r], s = typeof n == "function" ? n(o) : o[n], i = M(s) ? s : String(s);
    e[i] = (e[i] || []).concat(o);
  }
  return e;
}, Mt = (t, n = y.HLS) => {
  for (let e = t.length - 1; e > -1; e -= 1)
    if (t[e].endsWith(n))
      return t[e];
  return t[0];
}, Bt = async (t) => {
  var r;
  const n = (r = t == null ? void 0 : t.getReader) == null ? void 0 : r.call(t);
  if (!n) return;
  const e = await n.read();
  return new TextDecoder().decode(e.value);
}, At = (t, n, e = "...") => !t || t.length <= n ? t : `${t.substring(0, n)}${e}`, Dt = (t) => !!t && t.trim() !== "", Ot = (t) => !!t && String(t).toLowerCase() !== "false" && t !== "0", Rt = ({
  text: t,
  /** Lenght of the step for reducing the size */
  lengthStep: n,
  /** Size reduction for each step  */
  sizeStep: e = 0.1,
  /** Minimum size */
  min: r = 0.5
}) => {
  const o = (t || "").length, s = 1 - Math.floor(o / n) * e;
  return s < r ? r : s;
}, Ft = `  
`, Tt = (t, n = "-") => t.join(n), jt = (t = "") => t === "" ? "" : ` ${t}`, xt = ({
  params: t,
  relative: n = !1
} = {}) => {
  const e = new URL(window.location.href);
  if (t)
    for (const r of Object.keys(t))
      e.searchParams.set(r, String(t[r]));
  return n ? `${e.pathname}${e.search}` : e.href;
}, Nt = ({
  relative: t = !1,
  queryString: n = !0
} = {}) => {
  const e = new URL(window.location.href);
  return n || (e.search = ""), t ? `${e.pathname}${e.search}` : e.href;
}, Lt = (t) => t ? /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i.test(
  t
) : !1, D = new RegExp("^(?:[a-z+]+:)?//", "i"), bt = (t) => !!t && D.test(t), Ut = (t, n) => {
  const e = new URL(t);
  for (const r of Object.keys(n))
    e.searchParams.set(r, String(n[r]));
  return e.href;
};
function vt(t) {
  return t && t.replace(/(wss?:\/\/|wss?:)/g, "").replace("/socket", "").replace("/websocket", "");
}
const Yt = (t) => t ? t == null ? void 0 : t.reduce((n, e) => (e.platform === "email" || (n[e.platform] = e.url), n), {}) : void 0, Pt = (t) => t ? w.reduce((n, e) => {
  const r = t[e];
  return !r || e === "email" || n.push({
    platform: e,
    url: r
  }), n;
}, []) : void 0;
export {
  K as MS_DAY,
  X as MS_HOUR,
  tt as MS_MINUTE,
  Ut as addQueryParams,
  Et as areDeepEqual,
  q as arrayToMap,
  ct as bindEvents,
  xt as buildCurrentUrl,
  Tt as buildKey,
  Rt as calcResponsiveSize,
  L as compose,
  H as containsAll,
  I as containsAny,
  Q as convertBytes,
  nt as dateOrNow,
  ft as decodeHtmlEntities,
  A as diff,
  Z as difference,
  p as durationSuffixDefaults,
  rt as durationToFormat,
  Yt as flattenSocials,
  G as formatBytes,
  et as fromTo,
  St as get,
  Nt as getCurrentUrl,
  vt as getDomainKeyFromUrl,
  it as getFormattedLength,
  k as getRandomItem,
  mt as getRandomNumber,
  Mt as getRecordingURL,
  $t as groupBy,
  C as ifThen,
  F as isDefined,
  S as isFn,
  d as isFunction,
  N as isInstanceOf,
  lt as isJsonString,
  Dt as isNotEmpty,
  yt as isObject,
  bt as isRelativeUrl,
  x as isType,
  j as isTypeof,
  M as isValidKey,
  Lt as isValidUrl,
  J as joinOn,
  V as joinWithAnd,
  B as keysOf,
  at as mergeValues,
  l as multiplier,
  $ as navigatorLockSupported,
  Ft as newline,
  wt as omit,
  T as orFn,
  dt as parseJson,
  m as parseMilliseconds,
  Bt as parseStream,
  E as parsedDurationToFormat,
  b as pipe,
  U as promisify,
  P as retry,
  z as retryWithBackoff,
  gt as shortenNumber,
  a as sizes,
  Ot as toBoolean,
  ot as toEpoch,
  pt as toLimit,
  st as toRRule,
  At as truncate,
  v as tryCatch,
  Y as tryCatchAsync,
  ut as unbindEvents,
  Pt as unflattenSocials,
  W as uniqBy,
  _ as windowDefined,
  ht as withLock,
  jt as withSpace
};
