// ! should not contain any external libraries or chakra styles
// ! as this loader is being loaded before those libraries
import type { CSSProperties, HTMLAttributes, ReactElement, ReactNode } from 'react';
import { logoIconAnimated } from '../../Logo/icons';

const baseContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  margin: 'auto',
} as const;
const childStyles = {
  padding: '1rem',
};

export type FullscreenWrapperProps = {
  containerStyles?: CSSProperties;
  children?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export const FullscreenWrapper = ({
  children,
  containerStyles,
  ...rest
}: FullscreenWrapperProps): ReactElement | null => (
  <div style={{ ...baseContainerStyles, ...containerStyles }} {...rest}>
    <div style={childStyles}>{children}</div>
  </div>
);

export type PageLoaderProps = FullscreenWrapperProps & {
  logoProps?: HTMLAttributes<SVGElement>;
};
const PageLoader = ({ children, logoProps, ...rest }: PageLoaderProps): ReactElement | null => (
  <FullscreenWrapper {...rest}>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={125}
        height={125}
        viewBox="0 0 385 385"
        shapeRendering="geometricPrecision"
        textRendering="geometricPrecision"
        {...logoProps}
        style={{ margin: 'auto', ...logoProps?.style }}
      >
        {logoIconAnimated()}
      </svg>
    </div>
    {children}
  </FullscreenWrapper>
);

export const fullscreenLoader = (
  <PageLoader
    containerStyles={{
      position: 'fixed',
      top: '0',
      left: '0',
      zIndex: 1400,
      backgroundColor: 'white',
    }}
  />
);

export default PageLoader;
