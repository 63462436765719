const r = {
  enabled: !0,
  logLevel: 5,
  filter: void 0
}, o = {
  config: r,
  setConfig: (e) => (Object.assign(o.config, e), o),
  log: (...e) => (o.config.enabled && o.config.logLevel === 5 && console.log(...e), o),
  l: (...e) => o.log(...e),
  logLvl: (e, ...l) => (o.config.enabled && o.config.logLevel >= (e ?? 5) && console.log(...l), o),
  ll: (...e) => o.logLvl(...e),
  logType: (e, ...l) => (o.config.enabled && console[e](...l), o),
  lt: (...e) => o.logType(...e),
  logService: (e, l, ...g) => {
    const n = o.config.filter;
    return (!n || n.includes(e)) && o.logLvl(l, `[${e}]:`, ...g), o;
  },
  ls: (...e) => o.logService(...e),
  report: (e) => {
    var l, g;
    return (g = (l = o.config).report) == null || g.call(l, e), o.logType("error", e), o;
  },
  message: (e, l) => {
    var g, n;
    return (n = (g = o.config).message) == null || n.call(g, e, l), o.logType(l || "log", l), o;
  },
  breadcrumb: (e) => {
    var l, g;
    return (g = (l = o.config).breadcrumb) == null || g.call(l, e), e.message && o.log(e.message), o;
  }
};
export {
  o as logger
};
