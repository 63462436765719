// ! should not contain any external libraries or chakra styles or translations
// ! as this error page is being shown before those libraries are loaded
import { colors } from '@waitroom/config';
import { logger } from '@waitroom/logger';
import { useEffect } from 'react';
import { commonConfig } from '../../../config';
import { deleteCache, onLoadError, unregister } from '../../../services/serviceWorker';
import { FullscreenWrapper } from '../../Loader/Page/PageLoader';
import { LogoIcon } from '../../Logo/Logo';

export type FullscreenErrorProps = {
  onLogin?: () => void;
};

const buttonStyle = {
  padding: '.9rem 1.5rem',
  minWidth: '150px',
  fontSize: '1rem',
  fontWeight: 600,
  border: 0,
  borderRadius: '3rem',
  background: colors.red[800],
  color: '#fff',
  cursor: 'pointer',
};

const buttonSimpleStyle = {
  padding: 0,
  margin: 0,
  border: 'none',
  textDecoration: 'underline',
};

export const onError = (err: Error) => {
  logger.report(err);
  onLoadError(err);
};

const FullscreenError = ({ onLogin }: FullscreenErrorProps) => {
  useEffect(() => {
    deleteCache();
    unregister();
  }, []);

  return (
    <FullscreenWrapper
      containerStyles={{
        maxWidth: '550px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          gap: '1.5rem',
        }}
      >
        <LogoIcon w={80} />
        <h1 style={{ fontSize: '2rem', fontWeight: 'bold', lineHeight: 1.25 }}>
          Something went wrong — please refresh
        </h1>
        <p style={{ fontSize: '1.1rem' }}>
          We're experiencing technical difficulties and couldn't load this page. Try{' '}
          <button style={buttonSimpleStyle} onClick={() => window.location.reload()}>
            refreshing
          </button>{' '}
          your browser,
          {!!onLogin && (
            <>
              or if the issue continues,{' '}
              <button style={buttonSimpleStyle} onClick={onLogin}>
                log out
              </button>{' '}
              and back in,
            </>
          )}
          or{' '}
          <a
            href={`mailto:${commonConfig.company.emails.support}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            contact support
          </a>
          .
        </p>
        <p style={{ fontSize: '1.1rem' }}>Thank you for your patience!</p>
        <button
          type="button"
          className="refresh"
          style={buttonStyle}
          onClick={() => window.location.reload()}
        >
          Refresh page
        </button>
      </div>
    </FullscreenWrapper>
  );
};

export default FullscreenError;
