import { config as commonConfig } from '@waitroom/config';
import type { FeatureFlags } from '@waitroom/models';
import { parseJson, toBoolean } from '@waitroom/utils';
import pkg from '../../../../package.json';
import { envs } from '../services/envs';
import type { Environment } from '../types';

const env = (envs.NODE_ENV || 'production') as Environment;
const customEnv = (envs.VITE_ENVIRONMENT || env) as Environment;

export { commonConfig };

export const environments = {
  env,
  customEnv: customEnv,
  isProd: customEnv === 'production',
  isDev: customEnv === 'development',
};

export const version = pkg.version;
export const isMaintenance = toBoolean(envs.VITE_MAINTENANCE);

export const featureFlags = parseJson<FeatureFlags>(envs.VITE_FEATURE_FLAGS || '{}') || {};

let _baseUrl = envs.VITE_BASE_URL || window.location.origin;
// patch amplify preview web app
if (envs.VITE_BASE_URL === 'AMPLIFY_PREVIEW') {
  _baseUrl = `https://pr-${envs.AWS_PULL_REQUEST_ID}.${envs.AWS_APP_ID}.amplifyapp.com`;
}
export const BASE_URL = _baseUrl;

const protocol = toBoolean(envs.VITE_IS_LOCAL) ? 'http' : 'https';
export const LUXOR_URL = String(`${protocol}://${envs.VITE_LUXOR_DOMAIN}`);
export const WORMHOLE_URL = String(`${protocol}://${envs.VITE_WORMHOLE_DOMAIN}`);
export const CDN_URL = envs.VITE_CDN_DOMAIN as string;
export const CDN_IMAGES_URL = `${CDN_URL}/images`;
export const CDN_VIDEOS_URL = `${CDN_URL}/videos`;
export const CDN_LOGO_URL = `${CDN_URL}/images/logo`;

export const metaData = {
  imageURL: `${BASE_URL}/assets/images/meta/og-image.jpg`,
};
