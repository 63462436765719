import { LOGGER_SERVICE_CHAT as y } from "@waitroom/models";
import { useState as p, useCallback as w, useEffect as v } from "react";
import { StreamChat as C } from "stream-chat";
let u;
const U = (t) => {
  u = t.logger;
}, _ = ({
  key: t,
  channelId: e,
  userId: s,
  token: c,
  allowAnonymous: d = !1
}) => {
  const [r, f] = p({ loading: !1 }), i = (a) => f((n) => ({ ...n, ...a })), l = d ? "public_session" : "private_session", m = w(
    async (a, n) => {
      if (!n) return i({ channel: void 0 });
      i({ loading: !0, error: void 0 });
      try {
        const g = n.channel(l, a);
        i({ channel: g, loading: !1 });
      } catch (g) {
        i({ loading: !1, error: g });
      }
    },
    [l]
  ), S = w(
    async (a, n) => {
      if (!n) return;
      const g = n.channel(l, a);
      i({ channel: g });
    },
    [l]
  );
  return v(() => {
    var n;
    if (!e || !r.user) return;
    u == null || u.logService(y, 3, "Connection to chat channel", e);
    const a = ((n = r.channel) == null ? void 0 : n.cid) !== e;
    r.channel ? a && m(e, r.client) : S(e, r.client);
  }, [r.client, r.user, e, S, r.channel, m]), v(() => {
    if (!d && (!s || !c)) return;
    u == null || u.logService(y, 3, "Initializing chat client", {
      userId: s,
      allowAnonymous: d
    }), i({ loading: !0, error: void 0 });
    const a = new C(t);
    let n = !1;
    const g = (s ? a.connectUser(
      {
        id: s
      },
      !c || typeof c == "string" ? c : async () => {
        try {
          const o = await c();
          if (!o) throw new Error("Failed to fetch token");
          return o;
        } catch (o) {
          return i({ loading: !1, error: o }), "";
        }
      }
    ) : a.connectAnonymousUser()).then((o) => {
      n || i({
        client: a,
        user: o == null ? void 0 : o.me,
        loading: !1,
        error: void 0
      });
    }).catch((o) => {
      u == null || u.report(o), i({ loading: !1, error: o });
    });
    return () => {
      n = !0, f({ client: void 0, user: void 0 }), g.then(() => {
        a.disconnectUser();
      });
    };
  }, [d, t, c, s]), r;
}, b = ({ channel: t, onChange: e, onNewMessage: s }) => {
  const [c, d] = p(!0), [r, f] = p([]);
  return v(() => {
    if (!t) return;
    (async () => {
      d(!0), await t.watch(), t.state.messages.length > 0 && (e && e(), f(t.state.messages)), d(!1);
    })();
  }, [t, e]), v(() => {
    if (!t) return;
    const i = (m) => {
      m.message && (s && s(m.message), e && e(), f(t.state.messages));
    }, l = (m) => {
      m.message && (e && e(), f(t.state.messages));
    };
    return t.on("message.new", i), t.on("message.updated", l), () => {
      t.off("message.new", i), t.off("message.updated", l);
    };
  }, [t, e, s]), { messages: r, loading: c };
}, M = ({ channel: t }) => {
  const [e, s] = p(), c = w(
    async (d, r) => {
      try {
        s({ loading: !0 });
        const f = await (t == null ? void 0 : t.sendMessage(d, r));
        if (!f) throw new Error("Failed to send message");
        return s({ loading: !1 }), f.message;
      } catch {
        s({ error: "Unable to send, please retry", loading: !1 });
        return;
      }
    },
    [t]
  );
  return { ...e, onSend: c };
};
export {
  U as initConfig,
  u as logger,
  _ as useChatClient,
  b as useChatMessages,
  M as useChatMethods
};
